import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@service/settings/settings.service';
import { takeWhile } from 'lodash';
import { BehaviorSubject, interval, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(
    private http: HttpClient,
    public settingService: SettingsService,
  ) { }

  editStatus: any = [];

  readonly Time = 8000;
  public setWidthConfig(widthConfig: string[], tableScroll: { x: string; y: string; }): void {
    let width = 0;
    if (this.settingService.isManager || this.settingService.isTechnician) {
      width = 110;
    }
    if (width) {
      widthConfig.push(`${width}px`);
      tableScroll = {
        x: `${parseInt(tableScroll.x, 10) + width}px`,
        y: tableScroll.y,
      };
    }
  }

  addId(id: any): void {
    if (id) {
      if (this.inEditStatus(id)) {
        const item: any = {
          id,
          data: [],
          [`${id}$`]: this.getData(id),
          [`${id}Destroy`]: new Subject<any>(),
          [`${id}data`]: new BehaviorSubject<any>([]),
        };

        this.editStatus.push(item);

        item[`${item.id}$`]
          .pipe(
            takeUntil(item[`${id}Destroy`])
          )
          .subscribe(({ data = [] }: any) => {

            if (Array.isArray(data) && data.length === 0) {
              // item.id = null;
              // item[`${id}Destroy`].next();
              // item[`${id}Destroy`].complete();
              this.complete(item, id);
            } else if (Array.isArray(data) && data.length > 0) {

              item[`${id}data`].next(data);

              if (data.every((i: any) => i.status === 2 || i.status === 3)) {
                // item.id = null;
                // item[`${id}Destroy`].next();
                // item[`${id}Destroy`].complete();
                this.complete(item, id);
              }
            } else {
              this.complete(item, id);
              // item.id = null;
              // item[`${id}Destroy`].next();
              // item[`${id}Destroy`].complete();
            }

            console.log('订阅中。。。。');
          }, (err: any) => { }, () => {
            // console.log('更新完成');
          });
      }
    }
  }

  complete(item: any, id: any): void {
    item.id = null;
    item[`${id}Destroy`].next();
    item[`${id}Destroy`].complete();
  }

  inEditStatus(id: any): boolean {
    return this.editStatus.every((i: any) => i.id !== id);
  }

  getData(id: number): Observable<any> {
    return interval(this.Time)
      .pipe(
        startWith(0),
        switchMap(() => this.getList(id))
      );
  }

  getList(id: number): any {

    return this.http.post('taji/project/update/project/list', { id });

    // 测试
    // return of(
    //   {
    //     id,
    //     data: [
    //       {
    //         projectName: 'aaaaa' + id,
    //         status: parseInt(Math.random() * 3 + '', 10)
    //       },
    //       {
    //         projectName: 'bbbbbb' + id,
    //         status: parseInt(Math.random() * 3 + '', 10)
    //       },
    //     ]

    //   }

    // );
  }

  removeOberver(): void {
    this.editStatus.forEach((item: any) => {
      if (item[`${item.id}Destroy`]) {
        item[`${item.id}Destroy`].next();
        item[`${item.id}Destroy`].complete();
        item.id = null;
      }
    });
    this.editStatus = [];
  }

}
