import { NzMessageService } from 'ng-zorro-antd/message';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
  ) {}

  public onBlobDownload(api: string, fileType = 'xlsx'): void {
    const messageId = this.message.loading('正在下载中...', { nzDuration: 0 }).messageId;
    this.http.get(api, {
      responseType: 'blob',
    }).subscribe(
      (data: any) => {
        if (data instanceof Blob) {
          const blob = new Blob([data], { type: fileType });
          const fileName = format(new Date(), 'yyyy-MM-dd_HH_mm_ss');

          saveAs(blob, `${fileName}.${fileType}`);
        } else {
          this.message.error(data.msg);
        }
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }

  public onAssetsDownload(url: string, name?: string): void {
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    if (name) {
      a.download = name;
    }

    a.href = url;
    a.dispatchEvent(event);
  }
}
