import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LogListComponent } from '@shared/components/log-list/log-list.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SettingsService } from '@service/settings/settings.service';
import { DOCUMENT } from '@angular/common';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
@Injectable({ providedIn: 'root' })
export class ProgrammeService {
  constructor(
    private httpClient: HttpClient,
    private message: NzMessageService,
    private drawerService: NzDrawerService,
    private router: Router,
    private settingService: SettingsService,
    @Inject(DOCUMENT) document: any
  ) { }

  public setWidthConfig(widthConfig: string[], tableScroll: { x: string; y: string; }): void {
    let width = 0;
    if (this.settingService.isManager) {
      width = 300;
    } else if (this.settingService.isTechnician) {
      width = 250;
    }
    if (width) {
      widthConfig.push(`${width}px`);
      tableScroll = {
        x: `${parseInt(tableScroll.x, 10) + width}px`,
        y: tableScroll.y,
      };
    }
  }

  /**
   * 
   * @param id 
   * @param itemId 
   * @param title   
   * @param modelId 塔基臂长id
   */
  public onEdit(id: string, itemId: string, title?: string, modelId?: string): void {
    title = title ? title : '方案管理';
    const lastpage = document.location.hash.slice(1);
    this.router.navigate(['/plan', { id, itemId, lastpage, title, modelId }]);
  }

  public onDelete(id: string, successCb: () => void): void {
    const messageId = this.message.loading('正在删除中...', { nzDuration: 0 }).messageId;
    this.httpClient.post('tower/project/delete', { id }).subscribe(
      (res: any) => {
        const { code, msg } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.message.success('删除成功');
        successCb();
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }

  public onCommit(id: string, successCb: () => void): void {
    const messageId = this.message.loading('正在提交...', { nzDuration: 0 }).messageId;
    this.httpClient.post('tower/project/commit', { id }).subscribe(
      (res: any) => {
        const { code, msg } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.message.success('提交成功');
        successCb();
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }

  public onAudit(id: string, status: number, info: string | null = null, callback = () => { }): void {
    const messageId = this.message.loading('正在提交中...', { nzDuration: 0 }).messageId;
    this.httpClient.post('tower/project/audit', {
      id, status, info
    }).subscribe(
      (res: any) => {
        const { code, msg } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.message.success('操作成功');
        callback();
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }

  public onDownload(id: string): void {
    const messageId = this.message.loading('正在下载中...', { nzDuration: 0 }).messageId;
    const param = new FormData();
    param.append('id', id);
    this.httpClient.post('taji/project/download', param, { responseType: 'blob', observe: 'response' }).subscribe(
      (data: any) => {
        const { body: response, headers } = data;

        let fileName;
        try {
          fileName = decodeURI(headers.get('content-disposition')).split('fileName=')[1];
        } catch (error) {
          console.warn(error);
        }
        if (response instanceof Blob && response.type === 'application/octet-stream') {
          const blob = new Blob([response], { type: 'docx' });

          if (!fileName) {
            fileName = format(new Date(), 'yyyy-MM-dd_HH_mm_ss');
          }

          saveAs(blob, `${fileName}.docx`);
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            const json = JSON.parse(reader.result as any);
            this.message.error(json.msg);
          };

          reader.readAsText(response);
        }
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }

  public getLogs(id: string): void {
    this.drawerService.create<LogListComponent, { id: string }, void>({
      nzTitle: '操作日志',
      nzPlacement: 'right',
      nzWidth: 483,
      nzBodyStyle: {
        padding: '0',
      },
      nzContent: LogListComponent,
      nzContentParams: {
        id,
      }
    });
  }
}
